interface Video {
  id: string;
  nom: string;
  slug: string;
  date: string;
}
export const videosBourseMag: Video[] = [
  {
    id: 'K5CqievDkio',
    nom: "Il faut être stratège pour gagner en Bourse",
    slug: 'il-faut-etre-stratege-pour-gagner-en-bourse',
    date: 'février 2025',
  },
  {
    id: 'MlkDQ3fM5ZI',
    nom: "La première année de l'entreprise s'est soldée par une perte de 60%",
    slug: 'la-premiere-annee-de-lentreprise-sest-soldee-par-une-perte-de-60%',
    date: 'février 2025',
  },
  {
    id: 'eEZtoBDeZdo',
    nom: "De salarié à investisseur à la bourse de Tunis",
    slug: 'de-salarie-a-investisseur-a-la-bourse-de-tunis',
    date: 'janvier 2025',
  },
  {
    id: 'GmUu8FsPs10',
    nom: "La bourse, un monde d'hommes ?",
    slug: 'la-bourse-un-monde-dhommes',
    date: 'novembre 2024',
  },
  {
    id: 'QpnBQyVjVPQ',
    nom: 'Je veux investir, explique-moi !',
    slug: 'je-veux-investir-explique-moi',
    date: 'novembre 2024',
  },
  {
    id: 'FaLl7Ac1lno',
    nom: "Réussir en bourse : l'histoire de femmes inspirantes",
    slug: 'reussir-en-bourse-lhistoire-de-femmes-inspirantes',
    date: 'novembre 2024',
  },
 
];
